import React from 'react';

const PDFViewer = ({ pdfUrl }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="rounded-lg  w-full h-[800px]">
        <iframe
          src={pdfUrl}
          title="PDF Viewer"
          className="w-full h-full rounded-lg"
          style={{ border: 'none' }}
        />
      </div>
    </div>
  );
};

export default PDFViewer; 